export type Environment = "production" | "staging" | "local";

export default function getEnvironment(): Environment {
  const { releaseLevel } = useRuntimeConfig().public;

  if (releaseLevel === "production") {
    return "production";
  } else if (releaseLevel === "staging") {
    return "staging";
  } else if (releaseLevel === "local") {
    return "local";
  }
  return "production";
}

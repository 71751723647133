export type PopularShop = { title: string; slug: string; slugStaging: string };

export const popularShops: {
  allefolders: PopularShop[];
  promopromo: PopularShop[];
} = {
  allefolders: [
    { title: "Dirk", slug: "dirk", slugStaging: "dirk-demo" },
    {
      title: "Albert Heijn",
      slug: "albert-heijn",
      slugStaging: "albert-heijn-demo",
    },
    { title: "Kruidvat", slug: "kruidvat", slugStaging: "kruidvat-demo" },
    { title: "ALDI", slug: "aldi", slugStaging: "aldi-demo" },
    { title: "Lidl", slug: "lidl", slugStaging: "lidl-demo" },
    { title: "Action", slug: "action", slugStaging: "action-demo" },
    { title: "PLUS", slug: "plus", slugStaging: "plus-demo" },
    { title: "Jumbo", slug: "jumbo", slugStaging: "jumbo-demo" },
    { title: "Vomar", slug: "vomar", slugStaging: "vomar-demo" },
    { title: "Hoogvliet", slug: "hoogvliet", slugStaging: "hoogvliet-demo" },
  ],
  promopromo: [
    { title: "Lidl", slug: "lidl", slugStaging: "lidl-staging" },
    { title: "ALDI", slug: "aldi", slugStaging: "aldi-staging" },
    { title: "Action", slug: "action", slugStaging: "action" },
    { title: "Kruidvat", slug: "kruidvat", slugStaging: "kruidvat-staging" },
    {
      title: "Albert Heijn",
      slug: "albert-heijn",
      slugStaging: "albert-heijn-staging",
    },
    { title: "Cora", slug: "cora", slugStaging: "cora" },
    { title: "Carrefour", slug: "carrefour", slugStaging: "carrefour" },
    { title: "Delhaize", slug: "delhaize", slugStaging: "delhaize" },
    { title: "Zeeman", slug: "zeeman", slugStaging: "zeeman" },
    { title: "Wibra", slug: "wibra", slugStaging: "wibra-staging" },
  ],
};

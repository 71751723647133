<template>
  <nuxt-link class="base-logo" :to="localePath('/')">
    <BaseLogo />
    <BaseText class="logo-text">{{ $t(`title-${host}`) }}</BaseText>
  </nuxt-link>
</template>

<script setup lang="ts">
const localePath = useLocalePath();

const host = useCurrentHost();
</script>

<style lang="scss" scoped>
.base-logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: $light-100;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 14px 0;

  &.small-text-mobile {
    gap: 0.5rem;
    @include for-small-screen {
      .logo-text {
        font-size: 1.25rem;
      }
    }
  }
}

.logo-image {
  width: 60px;
  height: 60px;
}

.dark {
  color: $dark-100;
}
</style>
